.partitioning-viewer rect {
  fill: white;
  stroke: #333;
}
.partitioning-viewer rect:hover {
  cursor: pointer;
  fill: whitesmoke;
}

.partitioning-viewer path {
  stroke: #333;
}

.partitioning-viewer__name {
  text-align: center;
}

.partitioning-viewer__type {
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
}

foreignObject {
  pointer-events: none;
}
